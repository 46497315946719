/* Header styles */
.navbar {
  padding: 10px 0;
  background-color: #f5ffff; /* Background color for navbar */
}

.navbar-brand img {
  height: 50px; /* Logo size */
}

.navbar-toggler {
  border-color: transparent;
  background-color: #333; /* Background color for toggle button */
}

.navbar-toggler-icon {
  background-color: #333; /* Color of the hamburger icon */
}

.navbar-collapse {
  display: flex;
  flex-direction: column; /* Default to vertical layout */
  align-items: center;
  width: 100%;
}

.navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-nav .nav-item {
  padding: 10px 15px;
  width: 100%; /* Full width for each nav item */
  text-align: center; /* Center align text */
}

.nav-link {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #007bff;
  text-decoration: none;
}

/* For mobile view (screen sizes <= 991px) */
@media (max-width: 991px) {
  .navbar-collapse {
    flex-direction: column;
    align-items: center;
  }

  .navbar-nav .nav-item {
    width: 100%; /* Make items take full width on mobile */
  }

  /* Animation for mobile menu */
  .navbar-collapse.show {
    animation: slideDown 0.3s ease-out;
  }
}

/* For desktop view (screen sizes > 991px) */
@media (min-width: 992px) {
  .navbar-collapse {
    flex-direction: row; /* Align items horizontally on desktop */
    justify-content: flex-start; /* Align to the left */
    align-items: center; /* Vertically align */
  }

  .navbar-nav .nav-item {
    width: auto; /* Auto width for side-by-side layout */
  }
}

/* Slide-down animation for mobile menu */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
