.about-hero {
  background-image: url("https://www.kalyanrai.com/assets/images/web/1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  text-align: center;
  padding: 100px 20px; /* Adjust for mobile-friendly spacing */
}

.about-hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1;
}

.about-hero .container {
  position: relative;
  z-index: 2; /* Place content above the overlay */
}

.about-hero .page-title h2 {
  font-size: 3rem; /* Increase heading size */
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.about-hero .breadcrumb-custom {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 10px 0;
  font-size: 1rem;
  color: #ccc;
}

.about-hero .breadcrumb-custom li {
  position: relative;
  margin-right: 10px;
}

.about-hero .breadcrumb-custom li a {
  color: #fff;
  text-decoration: none;
}

.about-hero .breadcrumb-custom li::after {
  content: "/";
  color: #ccc;
  margin-left: 10px;
}

.about-hero .breadcrumb-custom li:last-child::after {
  content: "";
}

.about-us-section {
  background-color: #f4f4f4; /* Light background color */
  position: relative;
  padding: 50px 0;
}

.about-us-section .container {
  position: relative;
  z-index: 1;
}

.about-us-section .thumbnail-profile {
  position: relative;
  overflow: hidden;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow */
}

.about-us-section .thumbnail-image img {
  max-width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Image hover effect */
}

.about-us-section .thumbnail-image img:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.about-us-section h4 {
  font-size: 2rem; /* Increase heading size */
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.about-us-section p {
  font-size: 1rem;
  line-height: 1.7;
  color: #555;
  text-align: justify;
}

.about-us-section .text-spacing-50 {
  letter-spacing: 0.5px;
}

@media (max-width: 767px) {
  .about-us-section .row {
    flex-direction: column; /* Stack content on small screens */
  }

  .about-us-section .col-md-6,
  .about-us-section .col-lg-6 {
    margin-bottom: 20px; /* Add spacing between stacked columns */
  }

  .about-us-section h4 {
    text-align: center; /* Center the heading on smaller screens */
  }

  .about-us-section p {
    text-align: center; /* Center the paragraph text on smaller screens */
  }
}
