/* General Styles */
.contact-page {
  font-family: "Arial", sans-serif;
}

.page-title-wrap {
  padding: 50px 0;
  text-align: center;
  color: #fff;
}

.page-title h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.breadcrumb-custom {
  list-style: none;
  padding: 0;
  font-size: 1rem;
}

.breadcrumb-custom li {
  display: inline;
  margin-right: 5px;
}

.breadcrumb-custom li a {
  color: #fff;
  text-decoration: none;
}

.breadcrumb-custom li:after {
  content: ">";
  margin-left: 5px;
}

/* Contact Info Section */
.contact-info {
  font-size: 1.2rem;
  color: #333;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

/* Map Section */
.map-container {
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

/* Contact Form Section */
section.bg-gray-light {
  background-color: #f7f7f7;
  padding: 50px 0;
}

form .form-group label {
  font-weight: bold;
}

form .form-control {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
}

form button {
  margin-top: 15px;
}

form button.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
}

form button.btn-primary:hover {
  background-color: #0056b3;
}

.form-error {
  color: red;
  font-size: 0.9rem;
}

.thank-you-message {
  padding: 20px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: bold;
}

/* Animation for Thank you message */
.animate__animated.animate__fadeIn {
  animation-duration: 2s;
}
