@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.about-hero {
  background-image: url("https://www.kalyanrai.com/assets/images/web/1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  text-align: center;
  padding: 100px 20px; /* Adjust for mobile-friendly spacing */
}

.about-hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1;
}

.about-hero .container {
  position: relative;
  z-index: 2; /* Place content above the overlay */
}

.about-hero .page-title h2 {
  font-size: 3rem; /* Increase heading size */
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.about-hero .breadcrumb-custom {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 10px 0;
  font-size: 1rem;
  color: #ccc;
}

.about-hero .breadcrumb-custom li {
  position: relative;
  margin-right: 10px;
}

.about-hero .breadcrumb-custom li a {
  color: #fff;
  text-decoration: none;
}

.about-hero .breadcrumb-custom li::after {
  content: "/";
  color: #ccc;
  margin-left: 10px;
}

.about-hero .breadcrumb-custom li:last-child::after {
  content: "";
}

/* Basic Styling for Product Cards */
.post-modern {
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.post-header h5 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  padding: 15px;
}

.post-body figure img {
  max-width: 100%;
}

.post-inset p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  padding: 15px;
}

.post-footer {
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  padding: 15px;
}

.post-list {
  list-style: none;
  padding: 0;
}

.post-list li a {
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
}

.post-list li a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .post-modern {
    margin-bottom: 20px;
  }
}
