body {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

/* Slider.css */

/* Force carousel items to slide right-to-left */
.carousel-right-to-left .carousel-item-next,
.carousel-right-to-left .carousel-item-prev,
.carousel-right-to-left .carousel-item-end,
.carousel-right-to-left .carousel-item-start {
  transform: translateX(-100%);
}

.carousel-right-to-left .carousel-item.active {
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
p {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
.box-outline {
  background: #384142;
  text-align: center;
  border: none !important;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin: 0;
}

.feature-item {
  flex: 1 1 calc(33.333% - 20px); /* Three items per row */
  display: flex;
  justify-content: center;
  color: white;
  list-style: none;
}

.box-outline {
  background-color: #384142;
  color: white;
  padding: 20px;
  border: 1px solid #555;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 350px;
  max-width: 100%;
  text-align: left;
}

.box-outline img {
  margin-bottom: 15px;
}

.box-outline h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ddd;
}

.box-outline p {
  font-size: 0.9rem;
  color: #ccc;
}

@media (max-width: 768px) {
  .feature-item {
    flex: 1 1 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }

  .box-outline {
    min-height: auto;
  }
}

/* General Footer Styling */
.page-foot {
  background-color: #333;
  color: #eee;
  padding: 50px 20px;
  font-family: Arial, sans-serif;
}

.page-footer-title {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: bold;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  margin: 10px 0;
}

.footer-list a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-list a:hover {
  color: #fff;
}

/* Call Button Styling */
.call-button {
  display: inline-block;
  background-color: #0066cc;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.call-button:hover {
  background-color: #004999;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .container {
    text-align: center;
  }
  .col-sm-6 {
    margin-bottom: 30px;
  }
}

.footer-list .tooltip-link {
  position: relative;
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
}

.footer-list .tooltip-link:hover::after {
  content: "Coming Soon";
  position: absolute;
  bottom: 100%; /* Position above the link */
  left: 50%;
  transform: translateX(-50%);
  background-color: #222;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease;
}

.footer-list .tooltip-link::after {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
}

/* Default desktop typography */
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.75rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Mobile typography adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
