/* Slider.css */
.swiper-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caption {
  position: absolute;
  bottom: 20%;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 20px;
  border-radius: 8px;
}
.caption-text {
  font-size: 70px !important;
}
/* Responsive text sizing */
@media (max-width: 768px) {
  .caption-text {
    font-size: 32px !important;
  }
}

@media (max-width: 480px) {
  .caption-text {
    font-size: 24px !important;
  }
}

/* Custom Navigation Button Styles */
.swiper-button-next,
.swiper-button-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
}

/* Position adjustments */
.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.slide {
  position: relative;
  text-align: center;
}

.slide-img {
  width: 100%;
  height: auto;
  max-height: 70vh;
}

.caption {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.caption-text {
  color: white;
  font-size: 2rem;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .caption-text {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .caption-text {
    font-size: 1.2rem;
  }

  .slide-img {
    width: 100%;
    height: auto;
  }
}
