.go-up-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  background-color: #333;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.6;
}

.go-up-button:hover {
  opacity: 1;
  transform: scale(1.1);
}
